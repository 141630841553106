<template>
    <tr class="proxy">
        <td>
            <input
                    ref="proxyItem"
                    type="text"
                    :value="urlPort"
                    @input="typeof proxy.id === 'string' ? onInputNew($event) : onInput($event)"
            >
        </td>
        <td class="actions">
            <button
                    :title="!proxy.isProxySaved ? 'Сохранить' : ''"
                    type="button"
                    @click="typeof proxy.id === 'string' ? saveNewProxy() : updateProxy()"
                    :disabled="!urlPort"
            >
                <span v-if="proxy.isProxySaved" key="saved">
                    <ico name="icoDoneAll" color="#79919C"/>
                </span>
                <span v-else key="edited">
                    <ico name="icoDone" color="#79919C"/>
                </span>
            </button>

            <button
                    title="Удалить"
                    type="button"
                    @click="confirmRemovingProxy"
            >
                <ico name="icoDelete" color="#79919C"/>
            </button>
        </td>
    </tr>
</template>
<script>
    import {eventBus} from "@/main"

    export default {
        name: "ProxyItem",
        props: ['proxy'],

        computed: {
            urlPort() {

                return (this.proxy.url ?? '') + ':' + (this.proxy.port ?? '')
            }
        },
        methods: {

            onInputNew(event) {
                const newProxy = event.target.value.split(':')
                eventBus.$emit(
                    'changeNewProxy',
                    this.proxy.id,
                    {id: this.proxy.id, url: newProxy[0], port: newProxy[1] ? parseInt(newProxy[1]) : null}
                )
            },
            onInput(event) {
                const newProxy = event.target.value.split(':')
                eventBus.$emit(
                    'changeProxy',
                    this.proxy.id,
                    {id: this.proxy.id, url: newProxy[0], port: newProxy[1] ? parseInt(newProxy[1]) : null}
                )
            },
            updateProxy() {
                eventBus.$emit('updateProxy', this.proxy.id, {url: this.proxy.url, port: this.proxy.port})
            },
            saveNewProxy() {
                eventBus.$emit('saveProxy', {id: this.proxy.id, url: this.proxy.url, port: this.proxy.port})
            },
            confirmRemovingProxy() {
                if (typeof this.proxy.id === 'string') {
                    eventBus.$emit('removeNewProxy', this.proxy.id)
                    return
                }
                eventBus.$emit('showConfirmation', this.proxy)
            }
        }
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .proxy {
        padding: 8px 0;

        td {
            vertical-align: middle;
            border: none;
            border-bottom: 2px solid @bg-color-light;
            padding: 4px 16px;

            &.actions {
                padding: 4px 0;
                width: 80px;

                button {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 8px;
                    width: 32px;
                    height: 32px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &:disabled {
                        opacity: 0.5;
                        cursor: text;
                    }
                }
            }

            input {
                display: block;
                border: none;
                padding: 4px 0;
                width: 100%;

                &:focus {
                    outline: none;
                }
            }
        }
    }
</style>