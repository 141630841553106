<template>

    <div
            class="source-item"
    >
        <div class="source-item-header">
            <p>{{site.name}}</p>
            <div class="source-item-header-buttons">
                <button
                        type="button"
                        class="btn btn-middle"
                        @click.self="toggleActive"
                        :disabled="isParserLogsEmptyBySource(site.source)"
                >
                    Список ошибок
                </button>
                <button
                        type="button"
                        class="btn btn-middle"
                        @click.self="deleteParserLogs"
                        :disabled="isParserLogsEmptyBySource(site.source)"
                >
                    Очистить список ошибок
                </button>
            </div>

        </div>
        <div
                v-if="isActive"
                class="source-item-content-in"
        >
            <div class="source-item-content-table">
                <table>
                    <thead>
                    <tr>
                        <th class="date">Дата и время ошибки</th>
                        <th class="ip">IP</th>
                        <th class="number">Номер закупки</th>
                        <th class="message">Ошибка</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ParserLog
                            v-for="(log, index) in logs(this.site.source)"
                            :key="'log_' + index"
                            :log="log"
                    />
                    </tbody>
                </table>
            </div>
            <Pagination
                    :page="page(site.source)"
                    :links="links(site.source)"
                    @onChangePagination="onChangePagination"
                    @onChangePage="onChangePage"
            ></Pagination>
        </div>
    </div>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex'
    import Pagination from "@/components/common/Pagination"
    import ParserLog from "@/components/parsersPage/ParserLog"
    import {serviceUnavailable} from "@/utils/constants/errorMessages"

    export default {
        name: "parserContent",
        props: ['site'],
        data() {
            return {
                isActive: false,
            }
        },
        components: {
            Pagination,
            ParserLog,
        },
        computed: {
            ...mapGetters('parsers', ['logs', 'links', 'page', 'isParserLogsEmptyBySource']),
            ...mapGetters('purchasesTableSettings', ['pagination']),
        },
        methods: {
            ...mapActions('parsers', ['getLogs', 'deleteParserLogsBySource']),
            onChangePagination() {
                this.getLogs({
                    source: this.site.source,
                    searchParams: {
                        size: this.pagination.value,
                    }
                })
            },
            onChangePage(page) {
                this.getLogs({
                    source: this.site.source,
                    searchParams: {
                        size: this.pagination.value,
                        page: page
                    }
                })
            },
            toggleActive() {
                this.isActive = !this.isActive
            },
            async deleteParserLogs() {
                try {
                    await this.deleteParserLogsBySource(this.site.source)
                    this.isActive = false
                    await this.getLogs({
                        source: this.site.source,
                        searchParams: {
                            size: this.pagination.value,
                        }
                    })
                } catch (e) {
                    this.$toast.error(serviceUnavailable + "(3)")
                }
            }
        },
        async created() {
            try {
                await this.getLogs({
                    source: this.site.source,
                    searchParams: {
                        size: this.pagination.value,
                    }
                })
            } catch (e) {
                this.$toast.error(serviceUnavailable + "(4)")
            }
        }
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .source-item {
        margin-bottom: 24px;

        &:empty {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: @br;
            padding: 8px 16px;
            background-color: @bg-color-light;

            p {
                font-size: 18px;
            }

            &-buttons {
                display: flex;
                justify-content: normal;

                button {
                    margin-left: 10px;
                }
            }
        }
    }

    .source-item-content-in {
        position: relative;
        margin-top: 16px;
        height: 350px;
    }

    .source-item-content-table {
        overflow: auto;
        margin-bottom: 16px;
        height: calc(100% - 48px - 16px);

        &::-webkit-scrollbar {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: #ffffff;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: @br;
            width: 8px;
            height: 8px;
            background-color: fade(#000000, 24%);

            &:hover {
                background-color: fade(#000000, 30%);
            }
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            text-align: left;

            th {
                position: sticky;
                top: 0;
                z-index: 1;
                vertical-align: bottom;
                border: none;
                border-bottom: 2px solid @bg-color-light;
                padding: 8px;
                font-weight: 500;
                color: @text-color-light;
                background-color: #ffffff;

                &.date {
                    width: 180px;
                }

                &.ip {
                    width: 120px;
                }

                &.number {
                    width: 180px;
                }

                &.message {
                    width: calc(100% - 120px - 180px - 180px);
                }
            }
        }
    }
</style>