<template>
    <div class="modal-content">
        <button
            class="btn btn-success add-proxy-btn"
            type="button"
            @click="addProxy"
        >
            Добавить прокси
        </button>
        <div class="proxy-wrapper">
            <table>
                <thead>
                <tr>
                    <th>IP-адрес:Порт</th>
                    <th class="actions"></th>
                </tr>
                </thead>
                <tbody>
                <ProxyItem
                    v-for="(proxy) in mapProxies"
                    :key="'proxy_' + proxy.id"
                    :proxy="proxy"
                />
                </tbody>
            </table>
        </div>
        <div v-if="isConfirmationVisible" class="confirmation">
            <div class="confirmation-in">
                <div class="ico-wrapper">
                    <ico name="icoHelp" color="#F84242" size="72"></ico>
                </div>
                <p>Вы действительно хотите удалить прокси <strong>{{confirmedProxy.url + ':' + confirmedProxy.port}}</strong>?</p>
                <div class="form-footer">
                    <button
                        class="btn btn-success"
                        type="button"
                        @click="removeProxy"
                    >
                        Да
                    </button>
                    <button
                        class="btn btn-transparent"
                        type="button"
                        @click="closeModal"
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProxyItem from "@/components/parsersPage/ProxyItem"
import {eventBus} from "@/main"
import {mapActions, mapGetters} from "vuex"
import {uuid} from "vue-uuid"
import {concat, find, isEqual, map, values} from "lodash"

export default {
    name: "ProxyModal",
    data() {
        return {
            isConfirmationVisible: false,
            confirmedProxy: null,
            confirmedProxyId: null,
            updatedProxies: {},
            newProxies: {}
        }
    },
    components: {
        ProxyItem,
    },
    computed: {
        ...mapGetters('proxies', ['proxies']),
        mapProxies() {
            const dbProxies = map(this.proxies, it => ({...it, isProxySaved: true}))

            return concat(
                values(this.newProxies),
                map(dbProxies, it => this.updatedProxies[it.id] ?? it),
            )
        }
    },
    methods: {
        ...mapActions('proxies', ['getProxies', 'deleteProxy', 'editProxy', 'createProxy']),
        addProxy() {
            const id = uuid.v4()
            this.newProxies = {
                ...this.newProxies,
                [id]: {
                    id,
                    url: '',
                    port: '',
                    isProxySaved: false,
                }
            }
        },
        changeNewProxy(id, editedProxy) {
            this.newProxies = {
                ...this.newProxies,
                [id]: {
                    ...editedProxy,
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        changeProxy(id, {isProxySaved, ...editedProxy}) {
            const saved = isEqual(find(this.proxies, ['id', id]), editedProxy)
            if (!saved) {
                this.updatedProxies = {
                    ...this.updatedProxies,
                    [id]: {
                        ...editedProxy,
                        isProxySaved: false,
                    }
                }
                return
            }

            // eslint-disable-next-line no-unused-vars
            const {[id]: removed, ...rest} = this.updatedProxies
            this.updatedProxies = rest
        },
        showModal(proxy) {
            this.confirmedProxy = proxy
            this.confirmedProxyId = proxy.id
            this.isConfirmationVisible = true
        },
        closeModal() {
            this.isConfirmationVisible = false
        },
        removeNewProxy(id) {
            // eslint-disable-next-line no-unused-vars
            const {[id]: removed, ...rest} = this.newProxies
            this.newProxies = rest
        },
        removeUpdatedProxy(id) {
            // eslint-disable-next-line no-unused-vars
            const {[id]: removed, ...rest} = this.updatedProxies
            this.updatedProxies = rest
        },
        async saveProxy(newProxyData) {
            const {id, url, port} = newProxyData
            try {
                await this.createProxy({
                    url,
                    port
                })
                this.removeNewProxy(id)
                await this.getProxies()
            } catch (e) {
                this.$toast.error(e.message)
            }

        },
        async removeProxy() {
            try {
                await this.deleteProxy(this.confirmedProxyId)
                this.removeUpdatedProxy(this.confirmedProxyId)
                await this.getProxies()
                this.closeModal()
            } catch (e) {
                this.closeModal()
                this.$toast.error(e.message)
            }
        },
        async updateProxy(proxyId, editedProxy) {
            const proxyData = {
                url: editedProxy.url,
                port: editedProxy.port,
            }
            try {
                await this.editProxy({proxyId, proxyData})
                this.removeUpdatedProxy(proxyId)
                await this.getProxies()
            } catch (e) {
                this.$toast.error(e.message)
            }
        }
    },
    created() {
        this.getProxies()
        eventBus.$on('showConfirmation', this.showModal)
        eventBus.$on('changeProxy', this.changeProxy)
        eventBus.$on('changeNewProxy', this.changeNewProxy)
        eventBus.$on('removeNewProxy', this.removeNewProxy)
        eventBus.$on('saveProxy', this.saveProxy)
        eventBus.$on('updateProxy', this.updateProxy)
    },
    beforeDestroy() {
        eventBus.$off('showConfirmation')
        eventBus.$off('changeProxy')
        eventBus.$off('changeNewProxy')
        eventBus.$off('removeNewProxy')
        eventBus.$off('saveProxy')
        eventBus.$off('updateProxy')
    }
}
</script>
<style lang="less" scoped>
@import '../../assets/less/variables.less';
.modal-content {
    position: relative;
}
.add-proxy-btn {
    margin-bottom: 24px;
}
.proxy-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 350px;
    &::-webkit-scrollbar {
        border-radius: @br;
        width: 8px;
        height: 8px;
        background-color: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: @br;
        width: 8px;
        height: 8px;
        background-color: fade(#000000, 24%);
        &:hover {
            background-color: fade(#000000, 30%);
        }
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        height: auto;
        text-align: left;
        th {
            position: sticky;
            top: 0;
            z-index: 1;
            vertical-align: bottom;
            border: none;
            border-bottom: 2px solid @bg-color-light;
            padding: 12px 16px;
            font-weight: 500;
            color: @text-color-light;
            background-color: #ffffff;
            &.actions {
                padding: 4px 0;
                width: 80px;
            }
        }
    }
}
.confirmation {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: fade(#ffffff, 75%);

    &-in {
        border-radius: 4px;
        padding: 24px;
        max-width: 352px;
        background-color: @bg-color;

        .ico-wrapper {
            margin: 0 auto 16px;
            width: 72px;
        }

        p {
            text-align: center;
            color: #ffffff;
        }

        .form-footer {
            padding-top: 24px;
        }
    }
}
</style>