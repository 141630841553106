<template>
    <tr :class="{critical: log.errorLevel === 'CRITICAL', high: log.errorLevel === 'HIGH', low: log.errorLevel === 'LOW'}">
        <td class="date">{{date}}</td>
        <td class="ip">{{ log.ip }}</td>
        <td class="number">
            <a :href="log.url" target="_blank">{{ log.proposal.number }}</a>
        </td>
        <td class="message" v-html="message"></td>
    </tr>
</template>
<script>
    export default {
        name: "ParserLog",
        props: ['log'],
        computed: {
            date() {
                const dateAdded = new Date(this.log.dateAdded)
                const formatter = new Intl.DateTimeFormat('ru-RU', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                })
                return formatter.format(dateAdded)
            },
            message() {
                return this.log.message.replace(/(?:\r\n|\r|\n)/g, '<br>')
            }
        }
    }
</script>
<style lang="less" scoped>
    @import '../../assets/less/variables.less';
    tr {
        &.low td {
            background-color: fade(@success-color, 10%);
        }
        &.high td {
            background-color: fade(@warning-color, 10%);
        }
        &.critical td {
            background-color: fade(@error-color, 10%);
        }
        td {
            vertical-align: top;
            border: none;
            border-bottom: 2px solid @bg-color-light;
            padding: 8px;
            &.date {
                width: 180px;
            }
            &.ip {
                width: 120px;
            }
            &.number {
                width: 180px;
            }
            &.message {
                width: calc(100% - 120px - 180px - 180px);
            }
        }
    }
</style>