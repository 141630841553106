<template>
    <div>
      <button
          type="button"
          class="btn btn-middle proxies-btn"
          @click.self="openProxyModal"
      >
        Список прокси
      </button>

      <ParserContent
            v-for="(site, index) in sites"
            :key="index"
            :site="site"
        />
    </div>
</template>
<script>
    import ProxyModal from "@/components/parsersPage/ProxyModal"
    import {ProxyModalProperties} from "@/utils/modalsProperties"
    import ParserContent from "@/components/parsersPage/ParserContent"
    import {
        ICE_TRADE,
        GOS_ZAKUPKI,
        BUTB,
        BELOIL,
        BUTB_PPT,
        NAFTAN,
        MINSKTRANS,
        BELARUSKALI,
        BIDMART
    } from "@/utils/parsersNames"

    export default {
        name: "Parsers",
        data() {
            return {
                sites: [
                    {
                        name: 'goszakupki.by',
                        source: GOS_ZAKUPKI,
                    },
                    {
                        name: 'zakupki.butb.by',
                        source: BUTB,
                    },
                    {
                        name: 'icetrade.by',
                        source: ICE_TRADE,
                    },
                    {
                        name: 'sapsrm.beloil.by',
                        source: BELOIL,
                    },
                    {
                        name: 'ppt.butb.by',
                        source: BUTB_PPT,
                    },
                    {
                        name: 'naftan.by',
                        source: NAFTAN,
                    },
                    {
                        name: 'zakupki.minsktrans.by',
                        source: MINSKTRANS,
                    },
                    {
                        name: 'belaruskali.by',
                        source: BELARUSKALI,
                    },
                    {
                        name: 'bidmart.by',
                        source: BIDMART,
                    },
                ]
            }
        },
        components: {
            ParserContent,
        },
        methods: {
            openProxyModal() {
                this.$modal.show(
                    ProxyModal,
                    null,
                    ProxyModalProperties
                )
            }
        }
    }
</script>
<style lang="less" scoped>
    .proxies-btn {
        margin-bottom: 24px;
    }
</style>